export default function useIntercom() {
  const newMessage = (body) => {
    if (!window.Intercom) {
      return;
    }

    window.Intercom("showNewMessage", body)
  };

  return {
    newMessage,
  };
}
